
export const AFFILIATE_QUERYSTRING_KEY = 'aff'; // main-site & qrp-pages

export const updateQueryStringParameter = function(uri, key, value) { // see https://stackoverflow.com/questions/5999118
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2');
  }
  return uri + separator + key + "=" + value;
}

export function prependCMSURL4CMSImageValue(CMSURL, imgsrc) {
    let imgsrcLower = typeof imgsrc==='string' ? imgsrc.toLowerCase() : imgsrc;
    return imgsrcLower.indexOf('https://')===0 || imgsrcLower.indexOf('http://')===0 ? imgsrc : (CMSURL + imgsrc);
}

export const getQueryParams = function(url) { // https://stackabuse.com/get-query-string-values-in-javascript/
  const params = {};
  if (!url || !url.includes('?')) {
    return params;
  }
  const paramArr = url.slice(url.indexOf('?') + 1).split(/&amp;|&/);
  paramArr.map(param => {
      const [key, val] = param.split('=');
      params[key] = decodeURIComponent(val);
  })
  return params;
}

// copy from f1m1desk
export const AvifCheckerModule = (function(){
    const SESS_KEY = 'storageIsAvifSupported';

    const isAvifSupportedPromise = () => {
        if (!isAvifNotChecked()) {
            return Promise.resolve(isAvifSupported())
        }
        return new Promise(resolve => {
            const img = new Image();
            img.src = location.protocol + '//' + location.host + '/static/images/1x1.avif';
            img.onload = () => { global.sessionStorage?.setItem(SESS_KEY, 'Y'); resolve(true); }
            img.onerror = () => { global.sessionStorage?.setItem(SESS_KEY, 'N'); resolve(false); }
        });
    };

    const isAvifSupported = () => {
        return global.sessionStorage?.getItem(SESS_KEY) === 'Y'
    }
    const isAvifNotSupported = () => {
        return global.sessionStorage?.getItem(SESS_KEY) === 'N'
    }
    const isAvifNotChecked = () => {
        return !isAvifSupported() && !isAvifNotSupported();
    }

    return {
        isAvifSupportedPromise,
    };
})();

//是否支持webp 格式 (copy from f1m1desk)
export function isWebPSupported() {
    if (typeof window === "undefined") return false;

    const elem = document.createElement("canvas");

    if (!!(elem.getContext && elem.getContext("2d"))) {
        // was able or not to get WebP representation
        return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
    }

    return false;
}
