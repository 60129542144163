/*
 * @Date: 2023-02-12 13:09:32
 * @LastEditors: simLarry
 * @LastEditTime: 2023-03-17 12:33:00
 * @FilePath: \F1-M1-QRP-CODE\config\Fatch.config.js
 */
import Error from './Fatch-Error.js'
import HostConfig from '$CONFIG/Host.config'
import Router from 'next/router';
import { AvifCheckerModule, isWebPSupported } from "../helpers/";

// 登录|超时 清除掉登录信息

function RemoveItemStorage(needToLogin){
	localStorage.removeItem('memberToken');
	localStorage.removeItem('memberInfo');
	localStorage.removeItem('refreshToken');
	localStorage.removeItem('username');
	sessionStorage.removeItem('loginStatus');
}

/**
 * @param { Function } fetch_promise
 * @param { number | any } timeout { 超时时间 }
 */
export function timeout_fetch(fetch_promise, timeout = 120000) {
	let timeout_fn = null;
	let timeout_promise = new Promise(function (resolve, reject) {
		timeout_fn = function () {
			reject('请求超时!!!');
		};
	});
	let abortable_promise = Promise.race([fetch_promise, timeout_promise]);
	setTimeout(function () {
		timeout_fn();
	}, timeout);

	return abortable_promise;
}

const makeFormatSupportValue = (avif, webp) => ({avif, webp})
function recursiveFillOriginalImageFieldUsingOtherSupportFormat(o, supportFormats=makeFormatSupportValue(false, true)) {
	if (Array.isArray(o)) {
		return o.map(v => recursiveFillOriginalImageFieldUsingOtherSupportFormat(v, supportFormats))
	} else if (o && typeof o === 'object') {
		let keys = Object.keys(o);
		const priorityExt = ['avif', 'webp'];
		for (let k of keys) {
			const camel = priorityExt.map(z=>k + z.substring(0,1).toUpperCase() + z.substring(1))
			const snake = priorityExt.map(z=>k + '_' + z);
			const isCamel = camel.every(_ => keys.includes(_));
			const isSnake = snake.every(_ => keys.includes(_));
			let item, currentExt = typeof o[k] === 'string' ? o[k].split('.').slice(-1)?.[0] : '';
			currentExt = typeof currentExt === 'string' ? currentExt.toLowerCase() : '';
			if (isCamel) {
				item = camel
			} else if (isSnake) {
				item = snake
			} else {
				continue;
			}

			for (let i=0; i<priorityExt.length; i++) {
				let ext = priorityExt[i];

				/**
				 * According to API doc, if original image is avif/webp, then _avif _webp fields WILL BE falsy value.
				 * When this case occurs, we need to take original image as output value.
				 */
				if (ext === currentExt && o[k]) {
					o[item[i]] = o[k]
				}

				let isSupport = supportFormats[ext];
				if (isSupport && currentExt === 'gif' && ext === 'avif') { isSupport = false; } // since API server does not convert to animated-avif yet
				if (isSupport && o[item[i]]) {
					o[k] = o[item[i]] // Forward Compatibility: still take o[k] as final output
					break;
				}
			}
			if (!o[k]) { // To avoid API value is null
				o[k] = location.protocol + '//' + location.host + '/static/images/logo.svg';
			}
		}

		for (let k of Object.keys(o)) {
			o[k] = recursiveFillOriginalImageFieldUsingOtherSupportFormat(o[k], supportFormats)
		}

		return o;
	}
	return o
}


/**
 * @param { string } url
 * @param { string } method
 * @param { JSON } params
 * @param { Boolean } removeToken
 * @returns
 * 
 */
export const fetchRequest = (url , method , params = '' , Apiversion = '', removeToken = false)=>{
	let header;
	const IS_CMS_API = url.includes('/cms/');
	if(!IS_CMS_API){
		header = {
			'Content-Type': 'application/json; charset=utf-8',
			'Culture': 'zh-cn'
		};
	}

	return new Promise(function(resolve , reject){
		timeout_fetch(fetch(url + Apiversion , {
			method : method,
			headers : header,
			body: !params ? null : JSON.stringify(params)
			
		})).then((response) => response.json())
		   .then((data) =>{
				return AvifCheckerModule.isAvifSupportedPromise().then(b => {
					return [b, isWebPSupported(), data]
				});
			}).then(([isAvif, isWebP, data]) => {
			console.log(data)
			if(IS_CMS_API) {
				data = recursiveFillOriginalImageFieldUsingOtherSupportFormat(data, makeFormatSupportValue(isAvif, isWebP));
			}
			if(data.error_details){
				if(data.error_details.Code === "GEN0002"){
					Router.push('/restrict');
					return;
				}
			}
			   resolve(data);
		   })
	})
}


